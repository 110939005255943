import React, { useState } from 'react';
import './Samples.scss';

function Samples(props) {
  const [current, setCurrent] = useState(0);
  const { title, samples, showModal } = props.data;
  const buttons = samples.map((item, index) =>
    <button className={current === index ? 'current page-button' : 'page-button'} key={index} type="buttom" onClick={() => setCurrent(index)}>{`Goto Page ${index + 1}`}</button>
  );
  const slides = samples.map((group, index) =>
    <div className="sample-page" key={index} style={{ width: `${100 / samples.length}%` }}>{group.map(item =>
      <button className={`${item.class} sample-button`} disabled={current !== index} type="button" key={item.id} onClick={e => showModal(e, item.name, item.url)}>{`View ${item.title} Sample`}</button>
    )}</div>
  );
  return (
    <React.Fragment>
      <section className={title.toLowerCase() + ' samples'}>
        <div className="content">
          <div className="sample-menu">
            {buttons}
          </div>
          <div className="sample-cont" style={{ marginLeft: `-${100 * current}%`, width: `${samples.length * 100}%` }}>
            {slides}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Samples;
