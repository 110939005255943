import React, { useEffect, useState, useRef } from 'react';
import './Modal.scss';

function Modal(props) {
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const closeRef = useRef(null);
  const { imgName, imgSrc, showClass } = props.options;
  const onClk = (e) => {
    if (!closeRef.current.contains(e.target)) {
      closeRef.current.focus();
    }
  }
  const onKey = (e) => {
    if (e.key === 'Escape') {
      props.closeModal();
    }
    if (e.key === 'Tab') {
      e.preventDefault();
    }
  }
  useEffect(() => {
    document.addEventListener('click', onClk);
    document.addEventListener('keydown', onKey);
    return () => {
      document.removeEventListener('click', onClk);
      document.removeEventListener('keydown', onKey);
      setError(false);
      setLoaded(false);
    };
  }, []);
  useEffect(() => {
    if (error || loaded) {
      setTimeout(() => {
        closeRef.current.focus();
      }, 100);
    }
  }, [error, loaded]);
  return (
    <div className={showClass ? 'modal-container show' : 'modal-container hide'}>
      <div className="modal-dialog">
        <div className={`modal-header ${(error || loaded) ? 'show' : 'hide'}`}>
          <h3>{imgName}</h3>
          <button type="button" className="modal-close" onClick={props.closeModal} ref={closeRef}>Close</button>
        </div>
        <div className="modal-content">
          {!error && !loaded ? <p className="modal-loader">Loading Image...</p> : null}
          {error ? <p className="modal-error">Whoops! Something went wrong.</p> : null}
          <img src={imgSrc} className={`modal-image ${loaded ? 'show' : 'hide'}`} alt={imgSrc} onError={() => setError(true)} onLoad={() => setLoaded(true)} />
        </div>
      </div>
    </div>
  );
}

export default Modal;
