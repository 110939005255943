import React from 'react';
import sanitizeHtml from 'sanitize-html';
import './Section.scss';

function Section(props) {
  const { title, summary } = props.data;
  const cleanMarkup = sanitizeHtml(summary);
  const createMarkup = () => ({
    __html: cleanMarkup
  });
  return (
    <section className={title.toLowerCase() + ' section'}>
      <div className="content">
        <h2>{title.toUpperCase()}</h2>
        <div className="summary" dangerouslySetInnerHTML={createMarkup()}></div>
      </div>
    </section>
  );
}

export default Section;
