import React, { useState } from 'react';
import * as Design from 'assets/data/Design';
import * as Development from 'assets/data/Development';
import Footer from 'components/footer/Footer';
import Header from 'components/header/Header';
import Modal from 'components/modal/Modal';
import Samples from 'components/samples/Samples';
import Section from 'components/section/Section';
import './App.scss';

function App() {
  const [currentSample, setCurrentSample] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showClass, setShowClass] = useState(false);
  const [imgName, setImgName] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);

  const onShowModal = (e, name, src) => {
    e.preventDefault();
    setCurrentSample(e.target);
    setShowModal(true);
    setImgName(name);
    setImgSrc(src);
    setTimeout(() => {
      setShowClass(true);
    }, 30);
    document.body.classList.add('no-scroll');
  };

  const onCloseModal = () => {
    setShowClass(false);
    setTimeout(() => {
      setShowModal(false);
      setImgSrc(null);
    }, 300);
    currentSample.focus();    
    document.body.classList.remove('no-scroll');
  };
  
  return (
    <React.Fragment>
      <Header />
      <Section data={{ title: Development.title, summary: Development.summary }} />
      <Samples data={{ title: Development.title, samples: Development.samples, showModal: onShowModal }} />
      <Section data={{ title: Design.title, summary: Design.summary }} />
      <Samples data={{ title: Design.title, samples: Design.samples, showModal: onShowModal }} />
      <Footer />
      {showModal ? <Modal options={{ imgName: imgName, imgSrc: imgSrc, showClass: showClass }} closeModal={onCloseModal} /> : null}
    </React.Fragment>
  );
}

export default App;
