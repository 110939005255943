import React from 'react';
import './Header.scss';

function Header(props) {
  return (
    <header>
      <div className="content">
        <h1><span>Rick Reyes</span></h1>
        <div className="contact-menu">
          <a className="contact-item resume" href={process.env.PUBLIC_URL + '/rick-reyes-resume.pdf'} rel="noopener noreferrer" target="_blank"><span>Get </span>Resume</a>
          <a className="contact-item email" href="mailto:rickreyes.me@gmail.com"><span>Send </span>Email</a>
          <a className="contact-item phone" href="tel:+1-214-253-9059">Call<span> Now</span></a>
        </div>
      </div>
    </header>
  )
}

export default Header;
