import apps from 'assets/images/development/samples/dealer-app-list.png'
import flm from 'assets/images/development/samples/flm.jpg'
import auto1 from 'assets/images/development/samples/auto-combo.png'
import auto2 from 'assets/images/development/samples/auto-landing-page.jpg'
import dealer from 'assets/images/development/samples/dealer-combo.png'
import dc from 'assets/images/development/samples/dc.png'
import sas from 'assets/images/development/samples/sas.png'
import exam from 'assets/images/development/samples/vv-exam-room.png'
import offshoot from 'assets/images/development/samples/offshoot.jpg'
import reactive from 'assets/images/development/samples/reactive.jpg'
import skyline from 'assets/images/development/samples/skyline.jpg'
import metro from 'assets/images/development/samples/metro.jpg'
import skin12a from 'assets/images/development/samples/skin12a.jpg'
import skin14a from 'assets/images/development/samples/skin14a.jpg'
import dental from 'assets/images/development/samples/dentalmatrix.jpg'
import icontrol from 'assets/images/development/samples/icontrol.png'
import frames from 'assets/images/development/samples/frame-selector.png'
import irefer from 'assets/images/development/samples/irefer.png'
import chiroV2 from 'assets/images/development/samples/chiromatrix-v2.jpg'
import prosport from 'assets/images/development/samples/prosport.jpg'

export const title = 'Development';

export const summary = `
  <p>With over 14 years professional front-end architecture, development and UI design experience, you can count on pixel accurate layout with strict adherence to best practice.</p>
  <p>I specialize in mobile-first front-end development and UI engineering using industry standard HTML5, CSS3 and Javascript. </p>
  <p>As a result, I am able to deliver a streamlined UI framework architecture that will respond and adapt to all major device types, browsers and platforms.</p>
`;

export const samples = [
  [
    {
      class: 'apps',
      desc: '',
      id: '001',
      name: 'Dealer Applications List',
      url: apps
    },
    {
      class: 'flm',
      desc: '',
      id: '002',
      name: 'Financial Literacy Microsite',
      url: flm
    },
    {
      class: 'auto1',
      desc: '',
      id: '003',
      name: 'Auto Navigator A',
      url: auto1
    },
    {
      class: 'auto2',
      desc: '',
      id: '004',
      name: 'Auto Navigator B',
      url: auto2
    }
  ],
  [
    {
      class: 'dealer',
      desc: '',
      id: '005',
      name: 'Dealer Navigator',
      url: dealer
    },
    {
      class: 'dc',
      desc: '',
      id: '006',
      name: 'Delay Calculator',
      url: dc
    },
    {
      class: 'sas',
      desc: '',
      id: '007',
      name: 'Solid Accounting Solutions',
      url: sas
    },
    {
      class: 'exam',
      desc: '',
      id: '008',
      name: 'Verizon Virtual Visits',
      url: exam
    }
  ],
  [
    {
      class: 'offshoot',
      desc: '',
      id: '009',
      name: 'Doctorlogic Offshoot',
      url: offshoot
    },
    {
      class: 'reactive',
      desc: '',
      id: '010',
      name: 'Doctorlogic Reactive',
      url: reactive
    },
    {
      class: 'skyline',
      desc: '',
      id: '011',
      name: 'Doctorlogic Skyline',
      url: skyline
    },
    {
      class: 'metro',
      desc: '',
      id: '012',
      name: 'Doctorlogic Metro',
      url: metro
    }
  ],
  [
    {
      class: 'skin12a',
      desc: '',
      id: '013',
      name: 'iMatrix Skin 12a',
      url: skin12a
    },
    {
      class: 'skin14a',
      desc: '',
      id: '014',
      name: 'iMatrix Skin 14a',
      url: skin14a
    },
    {
      class: 'icontrol',
      desc: '',
      id: '015',
      name: 'iMatrix iControl CMS',
      url: icontrol
    },
    {
      class: 'dental',
      desc: '',
      id: '016',
      name: 'Dental Matrix',
      url: dental
    }
  ],
  [
    {
      class: 'frames',
      desc: '',
      id: '017',
      name: 'iMatrix Frame Selector',
      url: frames
    },
    {
      class: 'irefer',
      desc: '',
      id: '018',
      name: 'DentalMatrix iRefer',
      url: irefer
    },
    {
      class: 'chiroV2',
      desc: '',
      id: '019',
      name: 'ChiroMatrix V2',
      url: chiroV2
    },
    {
      class: 'prosport',
      desc: '',
      id: '020',
      name: 'ChiroMatrix ProSport',
      url: prosport
    }
  ],
];