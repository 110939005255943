import sas from 'assets/images/design/samples/sas-card.png'
import seo from 'assets/images/design/samples/seo-guide.jpg'
import gen3 from 'assets/images/design/samples/gen3-postcard.jpg'
import success from 'assets/images/design/samples/success.jpg'
import vet from 'assets/images/design/samples/vet-prescription-ad.jpg'
import fng from 'assets/images/design/samples/florida-guard-poster.jpg'
import hummer from 'assets/images/design/samples/hummer.jpg'
import vivify from 'assets/images/design/samples/vivify.jpg'
import dcp from 'assets/images/design/samples/dcp-ad.png'
import pcard from 'assets/images/design/samples/pocket-card.jpg'
import scholarship from 'assets/images/design/samples/scholarship-ad.jpg'
import bravura from 'assets/images/design/samples/bravura.jpg'
import o22 from 'assets/images/design/samples/o22-card.png'
import reyescult from 'assets/images/design/samples/reyescult-card.png'
import ironworkers from 'assets/images/design/samples/ironworkers.png'
import write from 'assets/images/design/samples/write-institute.png'
import bptime from 'assets/images/design/samples/bp-time.jpg'
import martian from 'assets/images/design/samples/martian-holidy.jpg'
import brotherhood from 'assets/images/design/samples/brotherhood.png'
import hero from 'assets/images/design/samples/american-hero.jpg'

export const title = 'Design';

export const summary = `
  <p>My background in graphic design spans over 20 years and includes a wide variety of projects taken from concept to completion.</p>
  <h4>Projects Include:</h4>
  <ul>
    <li>128 Page Success magazine</li>
    <li>Printer friendly SEO user guide</li>
    <li>Full Hummer H2 vehicle wrap</li>
    <li>Corporate trade show displays</li>
    <li>Large format banners and posters</li>
    <li>Post cards, business cards, and more</li>
  </ul>
`;

export const samples = [
  [
    {
      class: "sas",
      desc: '',
      id: "001",
      name: "SAS Business Cards",
      url: sas
    },
    {
      class: "seo",
      desc: '',
      id: "002",
      name: "ChiroMatrix SEO User Guide",
      url: seo
    },
    {
      class: "gen3",
      desc: '',
      id: "003",
      name: "ChiroMatrix Gen3 Postcard",
      url: gen3
    },
    {
      class: "success",
      desc: '',
      id: "004",
      name: "Helmets To Hardhats Success Magazine",
      url: success
    }
  ],
  [
    {
      class: "vet",
      desc: '',
      id: "005",
      name: "VetMatrix Prescription Ad",
      url: vet
    },
    {
      class: "fng",
      desc: '',
      id: "006",
      name: "Hire A Hereo Florida Guardsmen Poster",
      url: fng
    },
    {
      class: "hummer",
      desc: '',
      id: "007",
      name: "Helmets To Hardhats Hummer H2",
      url: hummer
    },
    {
      class: "vivify",
      desc: '',
      id: "008",
      name: "Vivify Designs Postcard",
      url: vivify
    }
  ],
  [
    {
      class: "dcp",
      desc: '',
      id: "009",
      name: "ChiroMatrix DCP Ad",
      url: dcp
    },
    {
      class: "pcard",
      desc: '',
      id: "010",
      name: "Hire A Here Pocket Card",
      url: pcard
    },
    {
      class: "scholarship",
      desc: '',
      id: "011",
      name: "Hire A Here Scholarship Ad",
      url: scholarship
    },
    {
      class: "bravura",
      desc: '',
      id: "012",
      name: "Bravura Networks Ad &amp; Business Card",
      url: bravura
    }
  ],
  [
    {
      class: "o22",
      desc: '',
      id: "013",
      name: "Opulent22 Business Card",
      url: o22
    },
    {
      class: "reyescult",
      desc: '',
      id: "014",
      name: "Reyescult Business Card",
      url: reyescult
    },
    {
      class: "ironworkers",
      desc: '',
      id: "015",
      name: "Helmets To Hardhats Ironworkers Sticker",
      url: ironworkers
    },
    {
      class: "write",
      desc: '',
      id: "016",
      name: "Write Institute Learning Aids",
      url: write
    }
  ],
  [
    {
      class: "bptime",
      desc: '',
      id: "017",
      name: "Blanket &amp; Pillow Time",
      url: bptime
    },
    {
      class: "martian",
      desc: '',
      id: "018",
      name: "Martian Holiday",
      url: martian
    },
    {
      class: "brotherhood",
      desc: '',
      id: "019",
      name: "Helmets To Hardhats Brotherhood Sticker",
      url: brotherhood
    },
    {
      class: "hero",
      desc: '',
      id: "020",
      name: "Helmets To Hardhats American Hero Award",
      url: hero
    }
  ]
];